
import AYM__00__Flecha__Izquierda from './AYM__00__Flecha__Izquierda.svg';
import AYM__01__Flecha__Derecha from './AYM__01__Flecha__Derecha.svg';
import AYM__02__Tuerca__Verde from './AYM__02__Tuerca__Verde.svg';
import AYM__03__Tuerca__Azul from './AYM__03__Tuerca__Azul.svg';
import AYM__04__Audio from './AYM__04__Audio.svg';




const IconHome = [
    AYM__00__Flecha__Izquierda,
    AYM__01__Flecha__Derecha,
    AYM__02__Tuerca__Verde,
    AYM__03__Tuerca__Azul,
    AYM__04__Audio,






];

export default IconHome;